<template>
  <div class="question-result-target">
    <div class="questionnaire-head">
      <div class="head-left">
        <el-image class="head-icon" :src="require(`../../../assets/image/recommend.png`)" fit="contain"></el-image>
        <div class="head-title">职业生涯目标</div>
      </div>
      <el-image class="head-right" @click="sendExit" :src="require(`../../../assets/image/exit2.png`)" fit="contain"></el-image>
    </div>
    <div class="result-target-body">
      <div class="body-main">
        <div class="main-left">
          <el-image class="small" v-if="isContent" :src="require(`../../../assets/image/loading.gif`)" fit="fit"></el-image>
          <span v-else>{{ content }}</span>
        </div>
        <div class="main-right">
          <el-form :model="result_target" :rules="mergedRules" ref="rulForm" class="myform">
            <div class="form-top">
              <el-form-item label="职位" prop="occupation">
                <el-input class="form-input" size="small" v-model="result_target.occupation"></el-input>
              </el-form-item>
            </div>
            <div class="form-bottom">
              <div class="form-compo" v-for="(value, index) in result_target.target" :key="value.student_result_target_id">
                <div class="form-compo-div">
                  <el-form-item label="目标" :prop="`target.${index}.project`">
                    <el-input class="form-input" size="small" v-model="value.project"></el-input>
                  </el-form-item>
                </div>
                <div class="form-compo-div">
                  <el-form-item label="结束日期" :prop="`target.${index}.end_time`">
                    <el-date-picker style="width: 180px;" type="date" placeholder="结束日期" v-model="value.end_time" size="small" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"></el-date-picker>
                  </el-form-item>
                </div>
                <div class="form-compo-title-img insert-border" @click="addTarget" v-if="index === 0">
                  <div class="insert">新增</div>
                  <el-image class="img" :src="require(`../../../assets/image/add.png`)" fit="contain"></el-image>
                </div>
                <div class="form-compo-title-img delete-border" @click="removeTarget(value)" v-if="index !== 0">
                  <div class="delete">删除</div>
                  <el-image class="img" :src="require(`../../../assets/image/del.png`)" fit="contain"></el-image>
                </div>
              </div>
            </div>
          </el-form>
        </div>
      </div>
      <div class="body-bottom">
        <el-button class="restart-button" @click="restartButton">重新生成</el-button>
        <el-button class="next-button" @click="nextButton">下一步</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getTargetMessage, getResult, addResultTarget } from '../../../request/api'

export default {
  data() {
    return {
      questionnaire_result_id: undefined,
      isContent: true,
      result_target: {
        occupation: '',
        target: [
          {
            student_result_target_id: Date.now(),
            project: '',
            end_time: ''
          }
        ]
      },
      content: '',
      // 静态验证规则
      staticRules: {
        occupation: [{ required: true, message: '请输入职位', trigger: 'blur' }],
      },
    }
  },
  computed: {
    mergedRules() {
      let rules = { ...this.staticRules }
      this.result_target.target.forEach((target, index) => {
        rules[`target.${index}.project`] = [
          { required: true, message: `第${index + 1}个目标不能为空`, trigger: 'blur' }
        ]
        rules[`target.${index}.end_time`] = [
          { required: true, message: `第${index + 1}个结束日期不能为空`, trigger: 'blur' }
        ]
      })

      return rules
    }
  },
  methods: {
    sendExit () {
      this.$emit('update-value')
    },
    // 新增目标
    addTarget() {
      this.result_target.target.push({
        student_result_target_id: Date.now(),
        project: '',
        end_time: ''
      })
    },
    // 删除目标
    removeTarget(value) {
      let index = this.result_target.target.indexOf(value)
      if (index !== -1) {
        this.result_target.target.splice(index, 1)
      }
    },
    // 重置目标
    restartButton() {
      if (this.questionnaire_result_id === undefined) return
      if (this.isContent) return

      this.isContent = true
      this.getResultData()
    },
    async getResultData() {
      try {
        const res = await getResult(this.questionnaire_result_id, 4)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
        }

        this.content = res.data.data
        this.isContent = false
      } catch(err) {
        this.getResultData()
      }
    },
    // 下一步
    nextButton() {
      this.$confirm('这会更新你的目标，是否继续？', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$refs.rulForm.validate(async valid => {
          if (valid) {
            try {
              const res = await addResultTarget({occupation: this.result_target.occupation, target: JSON.stringify(this.result_target.target), questionnaire_result_id: this.questionnaire_result_id})
              if (res.status !== 200 || res.data.code !== '1000') {
                this.$message.error(res.data.msg)
                return
              }

              this.$message.success("修改成功");
              this.$router.push(
                {
                  path: '/questionResultTargetScore',
                  query: {
                    questionnaire_result_id: this.questionnaire_result_id
                  }
                }
              )
            } catch(err) {
              this.$message.error('保存错误', err);
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
      })
    },
    async init() {
      if (this.questionnaire_result_id === undefined) return

      try {
        const res = await getTargetMessage(this.questionnaire_result_id)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
        }

        const result = res.data.data
        this.content = result.result_short_tactics
        this.result_target.occupation = result.occupation
        if (result.target.length > 0) {
          this.result_target.target = result.target
        }
        this.isContent = false
      } catch(err) {
        this.$message.error('获取数据出错了', err)
      }
    }
  },
  created() {
    this.questionnaire_result_id = this.$route.query.questionnaire_result_id
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.question-result-target {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .result-target-body {
    background-color: #fff;
    height: 92%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    .body-main {
      height: 90%;
      display: flex;
      justify-content: space-between;
      .main-left {
        width: 40%;
        border: 1px solid #9fd5ff;
        border-radius: 4px;
        padding: 4px;
        box-sizing: border-box;
        overflow-y: auto;
        font-size: 14px;
        white-space: pre-wrap;
      }
      .main-right {
        width: 58%;
        border: 1px solid #9fd5ff;
        border-radius: 4px;
        padding: 10px;
        box-sizing: border-box;
        overflow-y: auto;
        font-size: 14px;
        .myform {
          display: flex;
          flex-direction: column;
          .form-top {
            .form-input {
              width: 190px;
            }
          }
          .form-bottom {
            display: flex;
            flex-direction: column;
            .form-compo {
              display: flex;
              .form-compo-div {
                width: 100%;
                .form-input {
                  width: 190px;
                }
              }
              .insert-border {
                border: 1px solid #0a82fa;
              }
              .delete-border {
                border: 1px solid #d81e06;
              }
              .form-compo-title-img {
                margin-top: 6px;
                border-radius: 6px;
                min-width: 60px;
                height: 24px;
                justify-content: center;
                display: flex;
                cursor: pointer;
                font-size: 14px;
                align-items: center;
                .insert {
                  margin-right: 4px;
                  color: #54aaff;
                }
                .delete {
                  margin-right: 4px;
                  color: #d81e06;
                }
                .img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }
      }
    }
    .body-bottom {
      height: 10%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .restart-button {
        background-color: #409eff;
        color: #fff;
        width: 100px;
        border: none;
        transition: background-color 0.3s ease;
        &:hover {
          background-color: #66b1ff;
        }
      }
      .next-button {
        background-color: #ffcf34;
        color: #000;
        width: 90px;
        border: none;
        transition: background-color 0.3s ease;
        &:hover {
          background-color: #ffe284;
        }
      }
    }
  }
  .questionnaire-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
}
</style>